import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, EffectCoverflow, Pagination } from "swiper";

import { arrowLeft } from "../../assets";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./style.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useElementOnScreen } from "../../hooks";

const OurTeam = () => {
  const { t } = useTranslation();
  const { teamsData } = useSelector((state) => state.dataSlidersSlice);
  const [sizeDisplay, setSizeDisplay] = useState(0);
  const [lookBooldisplay, setLookBooldisplay] = useState(false);
  const sliderRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      setSizeDisplay(window.innerWidth);
    };
    window.addEventListener("scroll", handleScroll);
    sizeDisplay < 870 ? setLookBooldisplay(true) : setLookBooldisplay(false);
  }, [sizeDisplay]);

  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  });

  useEffect(() => {
    if (isVisible) {
      sliderRef.current.swiper.autoplay.run();
      sliderRef.current.swiper.autoplay.running = true;
    }
  }, [isVisible]);

  if (teamsData.length === 0) return <></>;
  return (
    <section className="our-team">
      <div className="container">
        <h2 className="title" id="ourTeam">
          {t("ourTeamTitle")}
        </h2>
        <div ref={containerRef} className="swiper-inner">
          <Swiper
            ref={sliderRef}
            modules={[Navigation, Pagination, Autoplay]}
            loop={true}
            speed={800}
            pagination={{ clickable: true }}
            grabCursor={true}
            centeredSlides={lookBooldisplay}
            autoplay={isVisible && { delay: 3000 }}
            effect="slide"
            navigation={{
              prevEl: ".arrow.left",
              nextEl: ".arrow.right",
            }}
            breakpoints={{
              0: {
                slidesPerView: 1.4,
                spaceBetween: 50,
              },
              340: {
                slidesPerView: 1.4,
                spaceBetween: 20,
              },
              410: {
                slidesPerView: 1.8,
                spaceBetween: 10,
              },
              480: {
                slidesPerView: 2.1,
                spaceBetween: 20,
              },
              650: {
                slidesPerView: 2.6,
                spaceBetween: 40,
              },
              870: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              990: {
                spaceBetween: 20,
                slidesPerView: 4,
              },
              1250: {
                spaceBetween: 25,
                slidesPerView: 4,
              },
            }}
          >
            {teamsData?.map((human, index) => (
              <SwiperSlide key={index} className="swiper-card">
                <div
                  className="team-card"
                  style={{
                    backgroundImage: ` linear-gradient(180.05deg, rgba(0, 0, 0, 0) 24.39%, rgba(0, 0, 0, 0.81) 94.74%), url('${human?.avatar}')`,
                  }}
                >
                  <h3 className="team-card__title">{human?.full_name}</h3>
                  <p className="team-card__subtitle">{human?.position}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="arrow left">
            <img src={arrowLeft} alt=">" />
          </div>
          <div className="arrow right">
            <img
              src={arrowLeft}
              alt="<"
              style={{ transform: "rotate(180deg)" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
