import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { changeCommentsData, changePortfolio, changeTeamsData } from "./dataSlidersSlice"
import { standartAxios } from "../../common/helpers"

const initialState = {
  language: "ru",
  technologyData: [],
  ourServiceData: [],
  footerData: {},
  activeBar: false,
}

export const toTakeData = createAsyncThunk("toTakeData", async (arg, { dispatch }) => {
  try {
    const { data } = await standartAxios(arg.api, arg.language)
    switch (arg.api) {
      case "portfolio":
        dispatch(changePortfolio(data))
        break
      case "services":
        dispatch(changeOurServiceData(data))
        break
      case "reviews":
        dispatch(changeCommentsData(data))
        break
      case "staff":
        dispatch(changeTeamsData(data))
        break
      case "footer":
        dispatch(changeFooterData(data))
        break
    }
  } catch (err) {
    if (arg.language === "ru") {
      alert("ошибка соединения")
    } else if (arg.language === "en") {
      alert("Connection error")
    } else if (arg.language === "ky") {
      alert("Туташуу катасы")
    }
  }
})

const mainSlice = createSlice({
  name: "mainSlice",
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload
    },
    changeTechnologyData: (state, action) => {
      state.technologyData = action.payload
    },
    changeOurServiceData: (state, action) => {
      state.ourServiceData = action.payload
    },
    changeFooterData: (state, action) => {
      state.footerData = action.payload
    },
    changeActiveBar: (state, action) => {
      state.activeBar = action.payload
    },
  },
})

export const { changeLanguage, changeTechnologyData, changeOurServiceData, changeFooterData, changeActiveBar } =
  mainSlice.actions

export default mainSlice.reducer
