import React, { useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const blockAnimation = {
  hidden: {
    y: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 1,
    opacity: 1,
    transition: {
      duration: 0.7,
      delay: custom * 0.3,
      ease: "easeOut",
    },
  }),
};

const StackTech = () => {
  const { t } = useTranslation();
  const { technologyData } = useSelector((state) => state.mainSlice);

  const [technology, setTechnology] = useState({
    Front: false,
    Backend: false,
    Android: false,
    UXUI: false,
    Production: false,
    PM: false,
    IOS: false,
    QA: false,
  });

  const all = Object.values(technology)?.filter((item) => item).length;

  const checkBoxTechnology = [
    {
      id: 1,
      tech: "Frontend",
      value: technology.Frontend,
    },
    {
      id: 2,
      tech: "Backend",
      value: technology.Backend,
    },
    {
      id: 3,
      tech: "Android",
      value: technology.Frontend,
    },
    {
      id: 4,
      tech: "UX-UI",
      value: technology.UXUI,
    },
    {
      id: 5,
      tech: "PM",
      value: technology.PM,
    },
    {
      id: 6,
      tech: "Production",
      value: technology.Production,
    },
    {
      id: 7,
      tech: "IOS",
      value: technology.IOS,
    },
    {
      id: 8,
      tech: "QA",
      value: technology.QA,
    },
  ];

  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: 1, amount: 0.2 }}
      className="stack-tech"
    >
      <div className="container">
        <motion.h2 variants={blockAnimation} custom={1} className="title">
          {t("stackTitle")}
        </motion.h2>
        <motion.div
          variants={blockAnimation}
          custom={2}
          className="tech-filters"
        >
          {checkBoxTechnology?.map((i) => (
            <div key={i.id} className="filter">
              <input
                onClick={() =>
                  setTechnology({
                    ...technology,
                    [i.tech]: !technology[i.tech],
                  })
                }
                type="checkbox"
                className="custom-checkbox"
                id={i.tech}
                name={i.tech}
                value={i.tech}
              />
              <label htmlFor={i.tech}>{i.tech}</label>
            </div>
          ))}
        </motion.div>

        <motion.div variants={blockAnimation} custom={2} className="tech">
          <AnimatePresence initial={false} exitBeforeEnter={false}>
            {technologyData
              ?.filter((item) => technology[item?.type] || all === 0)
              ?.map((tech, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="tech__items"
                >
                  <div className="tech__img">
                    <img
                      src={tech.icon}
                      alt="html"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <h3 className="tech__title">{tech?.title}</h3>
                </motion.div>
              ))}
          </AnimatePresence>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default StackTech;
