import React, { useEffect } from "react";
import StackTech from "../../components/stackTech/stackTech";
import { useDispatch } from "react-redux";
import { toTakeTechnologyData } from "../../store/reducersRequest/dataSlidersSlice";

const StackTechSection = () => {
  const disptch = useDispatch();
  useEffect(() => {
    disptch(toTakeTechnologyData());
  }, []);

  return (
    <>
      <StackTech />
    </>
  );
};

export default StackTechSection;
