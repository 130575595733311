import React, { useEffect } from "react"

import BannerSection from "./section/BannerSection/BannerSection"
import OurServiceSection from "./section/OurServiceSection/OurServiceSection"
import DevStagesSection from "./section/DevStagesSection/DevStagesSection"
import StackTechSection from "./section/StackTechSection/StackTechSection"
import PortfolioSection from "./section/PortfolioSection/PortfolioSection"
import CommentsSection from "./section/CommentsSection/CommentsSection"
import AboutUsSection from "./section/AboutUsSection/AboutUsSection"
import OurTeamSection from "./section/OurTeamSection/OurTeamSection"
import Footer from "./components/footer/footer"
import { useDispatch, useSelector } from "react-redux"
import { toTakeData } from "./store/reducersRequest/mainSlice"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function App() {
  const dispatch = useDispatch()
  const { language } = useSelector((state) => state.mainSlice)

  useEffect(() => {
    dispatch(toTakeData({ language, api: "portfolio" }))
    dispatch(toTakeData({ language, api: "services" }))
    dispatch(toTakeData({ language, api: "reviews" }))
    dispatch(toTakeData({ language, api: "staff" }))
    dispatch(toTakeData({ language, api: "footer" }))
  }, [language])

  return (
    <>
      <ToastContainer />
      <BannerSection />
      <OurServiceSection />
      <DevStagesSection />
      <StackTechSection />
      <PortfolioSection />
      <CommentsSection />
      <AboutUsSection />
      <OurTeamSection />
      <Footer />
    </>
  )
}

export default App
