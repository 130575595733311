import React from 'react';
import Comments from "../../components/comments/comments";

const CommentsSection = () => {
   return (
      <>
       <Comments />
      </>
   );
};

export default CommentsSection;