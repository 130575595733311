import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch } from "react-redux";
import {
  changeActiveBar,
  changeLanguage,
} from "../../store/reducersRequest/mainSlice";
import { pages } from "../../StackTech";
import "./styles.scss";

const translateName = ["ru", "kg", "en"];

const HeaderNav = () => {
  const dispatch = useDispatch();
  const [visiblePopup, setVisiblePopup] = useState(false);
  const translateRef = useRef();
  const { t } = useTranslation();

  const changeLang = (e, lang) => {
    i18n.changeLanguage(e.target.getAttribute("value"));
    if (lang === "kg") {
      dispatch(changeLanguage("ky"));
    } else {
      dispatch(changeLanguage(lang));
    }
  };

  const toggleVisiblePopup = () => {
    setVisiblePopup(!visiblePopup);
  };

  return (
    <>
      <div className="header__info">
        <nav className="nav">
          {pages?.map((link) => (
            <Link
              key={link.id}
              to={link?.to}
              onClick={() => dispatch(changeActiveBar(false))}
              spy={true}
              smooth={true}
              offset={link?.offset}
              duration={500}
              className="nav__link"
            >
              {t(link?.text)}
            </Link>
          ))}
          {/* // для адаптивки */}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://geekstudio.bitrix24site.ru/welcome/"
          ></a>
        </nav>
        {/* // смена языка */}
        <div
          ref={translateRef}
          className="translate"
          onClick={toggleVisiblePopup}
        >
          <div style={{ textTransform: "capitalize" }}>{i18n.language}</div>
          <AnimatePresence>
            {visiblePopup && (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: "auto" }}
                exit={{ height: 0 }}
                transition={{ duration: 0.4, ease: "easeOut" }}
                style={{ overflow: "hidden" }}
                className="translate__popup"
              >
                {translateName
                  ?.filter((lang) => i18n?.language !== lang)
                  ?.map((lang, index) => (
                    <div
                      key={index}
                      className="translate__item"
                      value={lang}
                      onClick={(e) => changeLang(e, lang)}
                    >
                      {lang}
                    </div>
                  ))}
              </motion.div>
            )}
          </AnimatePresence>
          <FontAwesomeIcon
            className="translate__arrow"
            icon={faAngleDown}
            style={visiblePopup ? { transform: "rotate(180deg)" } : null}
          />
        </div>
      </div>
      {/* // кнопка связзаться */}
      <a
        className="header__callMe"
        target="_blank"
        rel="noreferrer"
        href="https://t.me/geekstudioq/"
      >
        <button className="header__contact">{t("contactUsBtn")}</button>
      </a>
    </>
  );
};

export default HeaderNav;
