import React from 'react';
import AboutUs from "../../components/aboutUs/aboutUs";

const AboutUsSection = () => {
   return (
      <>
       <AboutUs />
      </>
   );
};

export default AboutUsSection;