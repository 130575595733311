import React from "react";
import { geekLogo } from "../../assets";

import "./style.scss";
import { useTranslation } from "react-i18next";
import phone from "../../assets/footer/footer_phone.svg";
import email from "../../assets/footer/footer_email.svg";
import { useSelector } from "react-redux";

const Footer = () => {
  const { t } = useTranslation();
  const { footerData } = useSelector((state) => state.mainSlice);

  return (
    <footer className="footer" id="footer">
      <div className="container">
        <div className="footer__inner">
          <div className="footer__text">
            <div className="footer__logo">
              <img src={geekLogo} alt="" />
            </div>
            <div className="footer__social">
              {footerData?.sosial_medias?.map((i, ind) => (
                <a key={ind} target="_blank" href={i?.url} rel="noreferrer">
                  <img src={i?.icon} alt="iicon" width={24} height={24} />
                </a>
              ))}
            </div>
          </div>
          <div className="footer__links">
            <div className="email">
              <h3>{t("footerEmail")}</h3>
              <div className="data_imgs">
                <img src={email} alt="email" />
                <a
                  href="mailto:office@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {footerData?.email}
                </a>
              </div>
            </div>
            <div className="number">
              <h3>{t("footerPhone")}</h3>
              {footerData?.phone_numbers?.map((num, ind) => (
                <div key={ind} className="data_imgs">
                  <img src={phone} alt="phone" />
                  <a href={`tel:${num}`}>{num}</a>
                </div>
              ))}
            </div>
            <div className="contact-inner">
              <div className="address">
                <h3>{t("footerAdress")}</h3>
                {footerData?.addresses?.map((place, ind) => (
                  <a
                    key={ind}
                    target="_blank"
                    href={place?.link}
                    style={{
                      letterSpacing: "1px",
                      wordBreak: "break-word",
                      lineHeight: 1.5,
                    }}
                    rel="noreferrer"
                  >
                    {place?.address}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
