import axios from "axios"

const instance = axios.create()
instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const standartAxios = (url, lang) => {
  return instance({
    method: "GET",
    baseURL: `${process.env.REACT_APP_API_URL}${url}/`,
    headers: {
      "Accept-Language": `${lang}`,
    },
  })
}

export const axiosPostRequest = (postData, lang) => {
  return instance({
    method: "POST",
    baseURL: `${process.env.REACT_APP_API_URL}feedback/`,
    headers: {
      "Accept-Language": `${lang}`,
    },
    data: postData,
  })
}
