import { combineReducers, configureStore } from "@reduxjs/toolkit";
import dataSlidersSlice from "./reducersRequest/dataSlidersSlice";
import mainSlice from "./reducersRequest/mainSlice";
const reducer = combineReducers({
  dataSlidersSlice,
  mainSlice,
});
export const store = configureStore({
  reducer,
});
