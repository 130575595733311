import React from 'react';
import DevStages from "../../components/devStages/devStages";

const DevStagesSection = () => {
   return (
      <>
         <DevStages />
      </>
   );
};

export default DevStagesSection;