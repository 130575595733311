import React from 'react';
import OurService from "../../components/ourService/ourService";

const OurServiceSection = () => {
   return (
      <>
       <OurService />
      </>
   );
};

export default OurServiceSection;