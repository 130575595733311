import React from "react";
import { geekLogo } from "../../assets";
import "./style.scss";
import HeaderNav from "../headerNav/headerNav";
import { Link } from "react-scroll";
import i18n from "../../i18n";
import { useDispatch, useSelector } from "react-redux";
import { changeActiveBar } from "../../store/reducersRequest/mainSlice";

const Header = () => {
  const dispatch = useDispatch();
  const { activeBar } = useSelector((state) => state.mainSlice);
  if (activeBar === true) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "visible";
  }

  return (
    <section className="header">
      <div className="container">
        <div className="header__inner">
          <Link
            to="/"
            spy={true}
            smooth={true}
            duration={500}
            href=""
            className="header__logo"
          >
            <img src={geekLogo} alt="" width="100%" height="100%" />
          </Link>
          <div
            className={`header__decktop ${
              i18n.language === "en" ? `header__decktop__en` : ``
            }
            ${i18n.language === "kg" ? `header__decktop__kg` : ``}`}
          >
            <HeaderNav />
          </div>
          <div
            className="hamburger-lines"
            onClick={() => dispatch(changeActiveBar(!activeBar))}
          >
            <span
              className="line line1"
              style={activeBar ? { transform: "rotate(45deg)" } : null}
            ></span>
            <span
              className="line line2"
              style={activeBar ? { transform: "scaleY(0)" } : null}
            ></span>
            <span
              className="line line3"
              style={activeBar ? { transform: "rotate(-45deg)" } : null}
            ></span>
          </div>
        </div>
      </div>
      {/* // для адативки */}
      <div
        className="menu-items"
        onClick={() => dispatch(changeActiveBar(!activeBar))}
        style={activeBar ? { transform: "translateX(0)" } : null}
      >
        <div className="header__mobile">
          <HeaderNav />
        </div>
      </div>
    </section>
  );
};

export default Header;
