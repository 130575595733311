import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Autoplay, Navigation, Pagination } from "swiper";

import { arrowLeft } from "../../assets";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./style.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Portfolio = () => {
  const { t } = useTranslation();

  const { portfolioData } = useSelector((state) => state.dataSlidersSlice);
  return (
    <section className="portfolio" id="portfolio">
      <div className="container">
        <h2 className="title">{t("portfolio")}</h2>
      </div>
      <Swiper
        modules={[EffectCoverflow, Pagination, Autoplay, Navigation]}
        effect={"coverflow"}
        loop={true}
        speed={1000}
        grabCursor={true}
        pagination={{ clickable: true }}
        initialSlide={3}
        centeredSlides={true}
        autoplay={{ delay: 10000 }}
        navigation={{
          prevEl: ".arrow.left",
          nextEl: ".arrow.right",
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
            coverflowEffect: {
              stretch: -49,
            },
          },
          768: {
            slidesPerView: 1.2,
            spaceBetween: 60,
          },
          950: {
            spaceBetween: 80,
            slidesPerView: 1.6,
          },
        }}
      >
        {portfolioData?.map((i, ind) => (
          <SwiperSlide
            style={{
              background:
                "linear-gradient(360deg, rgba(0, 0, 0, 0) -14.31%, rgba(0, 0, 0, 0.412127) 25.58%, rgba(0, 0, 0, 0.81) 88.32%)",
            }}
            key={ind}
          >
            <div className="portfolio-card">
              <div className="card-text">
                <h3 className="card-text__title">{i?.title}</h3>
                <p
                  className="card-text__subtitle"
                  style={!i?.link ? { paddingTop: "40px" } : {}}
                >
                  {i?.description}
                </p>
                {i?.link ? (
                  <a target="_blank" href={i?.link} className="card-text__link">
                    {t("watchProject")}
                  </a>
                ) : (
                  <div className="card-text__noneBtn"></div>
                )}
              </div>
              <div className="card-img">
                <img
                  src={i?.icon}
                  alt="btn"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="arrow left">
        <img src={arrowLeft} alt="<" />
      </div>
      <div className="arrow right">
        <img src={arrowLeft} alt=">" style={{ transform: "rotate(180deg)" }} />
      </div>
    </section>
  );
};

export default Portfolio;
