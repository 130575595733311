import React from "react"
import Banner from "../../components/banner/banner"

const BannerSection = () => {
  return (
    <>
      <Banner />
    </>
  )
}

export default BannerSection
