import React, { useEffect, useState } from "react"
import "./style.scss"
import Header from "../header/header"
import { useTranslation } from "react-i18next"
import { Link } from "react-scroll"
import anchor from "../../assets/banner/anchor.svg"
import { useSelector } from "react-redux"
import { ModalWindow } from "../modalWindow/modalWindow"

const Banner = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)

  const [scrollPosition, setScrollPosition] = useState(0)
  const [lookAnchor, setLookAnchor] = useState(false)
  const { activeBar } = useSelector((state) => state.mainSlice)

  const handleOpenModal = (value) => {
    setOpenModal(value)
  }

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY)
    }
    window.addEventListener("scroll", handleScroll)
    scrollPosition < 550 ? setLookAnchor(false) : setLookAnchor(true)
  }, [scrollPosition])

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden"
    }
  }, [openModal])

  return (
    <>
      <Header />
      <section className="banner" id="/">
        <div className="container">
          <div className="banner__inner">
            <div className="banner__text">
              <h1 className="main-title">{t("mainTitle")}</h1>
              <p className="subtitle">{t("mainSubtitle")}</p>
              <button className="btn-more" onClick={() => handleOpenModal(true)}>
                {t("moreBtn")}
              </button>
            </div>
          </div>
        </div>
      </section>
      {!activeBar && lookAnchor && (
        <div className="anchor">
          <Link to="service" spy={true} smooth={true} offset={-2500} duration={800}>
            <img src={anchor} alt="^" />
          </Link>
        </div>
      )}
      <ModalWindow handleOpenModal={handleOpenModal} isShow={openModal} />
    </>
  )
}

export default Banner
