import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { standartAxios } from "../../common/helpers";
import { changeTechnologyData } from "./mainSlice";

const initialState = {
  portfolioData: [],
  commentsData: [],
  teamsData: [],
};

export const toTakeTechnologyData = createAsyncThunk(
  "toTakeTechnologyData",
  async (lang, { dispatch }) => {
    try {
      const { data } = await standartAxios(`stack`, lang);
      dispatch(changeTechnologyData(data));
    } catch (err) {
      console.log(err);
    }
  }
);

const dataSlidersSlice = createSlice({
  name: "dataSlidersSlice",
  initialState,
  reducers: {
    changePortfolio: (state, action) => {
      state.portfolioData = action.payload;
    },
    changeCommentsData: (state, action) => {
      state.commentsData = action.payload;
    },
    changeTeamsData: (state, action) => {
      state.teamsData = action.payload;
    },
  },
});
export const { changePortfolio, changeCommentsData, changeTeamsData } =
  dataSlidersSlice.actions;

export default dataSlidersSlice.reducer;
