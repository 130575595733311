import React from 'react';
import Portfolio from "../../components/portfolio/portfolio";

const PortfolioSection = () => {
   return (
      <>
       <Portfolio />
      </>
   );
};

export default PortfolioSection;