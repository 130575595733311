import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Autoplay, Navigation } from "swiper";
import { arrowLeft } from "../../assets";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./style.scss";
import useMediaQuery from "../../helpers";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Comments = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:550px)");

  const { commentsData } = useSelector((state) => state.dataSlidersSlice);

  return (
    <section className="comments">
      <div className="comments__inner">
        <div className="comments__inner__shadowLeft"></div>
        <div className="comments__inner__shadowRight"></div>
        <div className="comments__background" id="comments">
          <h2 className="title">{t("reviewsTitle")}</h2>
          <Swiper
            modules={[EffectCoverflow, Pagination, Autoplay, Navigation]}
            effect={"coverflow"}
            loop={true}
            speed={1000}
            pagination={{ clickable: true }}
            grabCursor={true}
            centeredSlides={true}
            initialSlide={4}
            autoplay={{ delay: 10000 }}
            navigation={{
              prevEl: ".arrow.left",
              nextEl: ".arrow.right",
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                coverflowEffect: {
                  stretch: -80,
                },
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 1.6,
                spaceBetween: 80,
              },
              950: {
                spaceBetween: 130,
                slidesPerView: 2,
              },
            }}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 150,
              modifier: 1,
              slideShadows: false,
            }}
          >
            {commentsData?.map((human, index) => (
              <SwiperSlide key={index}>
                <div className="comments__card">
                  <h3 className="card-title">{human?.full_name}</h3>
                  <p className="card-subtitle">{human?.position}</p>
                  <p className="card-paragraph">{human?.description}</p>
                  <div className="card-img">
                    <img
                      src={human?.avatar}
                      alt="user"
                      width={"100%"}
                      height={"100%"}
                      style={{ borderRadius: "50%" }}
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="arrow left">
            <img src={arrowLeft} alt="" />
          </div>
          <div className="arrow right">
            <img
              src={arrowLeft}
              alt=""
              style={{ transform: "rotate(180deg)" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Comments;

//  <Swiper
//    modules={[EffectCoverflow, Pagination, Autoplay, Navigation]}
//    effect={"coverflow"}
//    loop={true}
//    speed={1000}
//    initialSlide={3}
//    pagination={{ clickable: true }}
//    grabCursor={true}
//    centeredSlides={true}
//    autoplay={{ delay: 10000 }}
//    navigation={{
//      prevEl: ".arrow.left",
//      nextEl: ".arrow.right",
//    }}
//    breakpoints={{
//      0: {
//        slidesPerView: 1,
//        coverflowEffect: {
//          stretch: -50,
//        },
//      },
//      768: {
//        slidesPerView: 1.3,
//        spaceBetween: 60,
//      },
//      950: {
//        spaceBetween: 80,
//        slidesPerView: 1.6,
//      },
//    }}
//  >
//    {portfolioData?.map((project, index) => (
//      <SwiperSlide key={index}>
//        <div className="portfolio-card">
//          <div className="card-text">
//            <h3 className="card-text__title">{project?.title}</h3>
//            <p
//              className="card-text__subtitle"
//              style={!project?.link ? { paddingTop: "40px" } : {}}
//            >
//              {project?.description}
//            </p>
//            {project?.link ? (
//              <a
//                target="_blank"
//                href={project?.link}
//                className="card-text__link"
//              >
//                {t("watchProject")}
//              </a>
//            ) : (
//              <div className="card-text__noneBtn"></div>
//            )}
//          </div>
//          <div className="card-img">
//            <img
//              src={project?.icon}
//              alt="btn"
//              style={{ width: "100%", height: "100%" }}
//            />
//          </div>
//        </div>
//      </SwiperSlide>
//    ))}
//  </Swiper>;
