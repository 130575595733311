import React from "react";
import OurTeam from "../../components/ourTeam/ourTeam";

const OurTeamSection = () => {
  return (
    <>
      <OurTeam />
    </>
  );
};

export default OurTeamSection;
