export const pages = [
  {
    id: 1,
    to: "service",
    offset: -50,
    text: "service",
  },
  {
    id: 2,
    to: "portfolio",
    offset: -50,
    text: "portfolio",
  },
  {
    id: 3,
    to: "comments",
    offset: -90,
    text: "reviews",
  },
  {
    id: 4,
    to: "aboutUs",
    offset: -50,
    text: "about",
  },
  {
    id: 5,
    to: "ourTeam",
    offset: -100,
    text: "ourTeam",
  },
  {
    id: 6,
    to: "footer",
    offset: -400,
    text: "contacts",
  },
];
