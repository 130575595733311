import React, { useState } from "react"
import "./style.scss"
import { IMaskInput } from "react-imask"
import closeIcon from "../../assets/icon/close.svg"
import { notify } from "../../helpers"
import { useTranslation } from "react-i18next"
import { axiosPostRequest } from "../../common/helpers"
import { useSelector } from "react-redux"

const DESCRIPTION_LENGTH = 250

export const ModalWindow = ({ handleOpenModal, isShow }) => {
  const { t } = useTranslation()
  const { language } = useSelector((state) => state.mainSlice)
  const [formData, setFormData] = useState({
    full_name: "",
    phone_number: "",
    description: "",
  })

  const handleInputChange = (key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: key === "phone_number" ? value.replace(/[-() ]/g, "") : value,
    }))
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      const request = await axiosPostRequest(formData, language)
      handleOpenModal(false)
      notify.success(request.data.message)
      setFormData({
        full_name: "",
        phone_number: "",
        description: "",
      })
    } catch (error) {
      error.response.data.phone_number.forEach((message) => {
        notify.error(message)
      })
    }
  }

  const handleBgClick = (event) => {
    event.target.classList.contains("modalWindowWrapper") && handleOpenModal(false)
  }

  return (
    <div className={`modalWindowWrapper ${isShow && "show"}`} onMouseDown={handleBgClick}>
      <div className={`modalWindow ${isShow && "show"}`}>
        <img src={closeIcon} alt="icon" className="closeIcon" onClick={() => handleOpenModal(false)} />
        <div className="textWrapper">
          <h4 className="modalWindowTitle">{t("Welcome to GeekStudio")}</h4>
          <p className="subtitle">{t("Fill out the form to receive a free consultation")}</p>
        </div>
        <form className="form" onSubmit={(e) => handleSubmit(e)}>
          <div className="formItem">
            <label htmlFor="name" className="label">
              {t("Name")} <span className="star">*</span>
            </label>
            <input
              required
              onChange={(e) => handleInputChange("full_name", e.target.value)}
              value={formData.full_name}
              id="name"
              className="input"
              placeholder={t("Enter a name")}
            />
          </div>
          <div className="formItem">
            <label htmlFor="phone" className="label">
              {t("Phone")} <span className="star">*</span>
            </label>
            <IMaskInput
              required
              onChange={(e) => handleInputChange("phone_number", e.target.value)}
              id="phone"
              type="text"
              mask="+{996} (000) 000-000"
              placeholder="+996 (999) 999-999"
              className="input"
              value={formData.phone_number}
            />
          </div>
          <div className="formItem">
            <label htmlFor="comment" className="label">
              {t("Enter your question")}
            </label>
            <textarea
              onChange={(e) => handleInputChange("description", e.target.value)}
              value={formData.description}
              id="comment"
              className="textaria"
              placeholder={t("What would you like to know?")}
              maxLength={DESCRIPTION_LENGTH}
            />
            <div className="counter">
              {formData.description.length}/{DESCRIPTION_LENGTH}
            </div>
          </div>
          <button type="submit" className="submitBtn">
            {t("Send")}
          </button>
        </form>
      </div>
    </div>
  )
}
