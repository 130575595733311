import React from 'react';
import {about} from "../../assets";

import './style.scss'
import {useTranslation} from "react-i18next";


const AboutUs = () => {
   const {t} = useTranslation()
   
   return (
      <section className='about-us' id='aboutUs'>
         <div className="container">
            <h2 className="title">{t("aboutTitle")}</h2>
            <div className="about-us__inner">
               <p className='about-us__text'>{t("aboutUsText")}</p>
               <div className="about-us__img">
                  <img src={about} alt="" style={{width: '100%', height: '100%'}}/>
               </div>
            </div>
         </div>
      </section>
   );
};

export default AboutUs;